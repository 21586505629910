import Vue from "vue"
import Router from "vue-router"
import auth from "@/view/auth"
import index from "@/view/index"
// import indextwo from "@/view/indextwo"
Vue.use(Router)

const routes = [
  {
    path: "*",
    redirect: "/",
    meta: {},
  },
  {
    path: "/",
    name: "auth",
    component: auth,
  },

  {
    path: "/index",
    name: "index",
    component: index,
  },
  // {
  //   path: "/indextwo",
  //   name: "indextwo",
  //   component: indextwo,
  // },
  {
    path: "/area",
    name: "area",
    component: () => import("@/view/area.vue"),
  },
  {
    path: "/result",
    name: "result",
    component: () => import("@/view/result.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/view/register.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/view/login.vue"),
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () => import("@/view/forgot.vue"),
  },
  {
    path: "/forgotxc",
    name: "forgotxc",
    component: () => import("../view/forgotxc"),
  },
  {
    path: "/entrance",
    name: "entrance",
    component: () => import("@/view/entrance.vue"),
  },
  {
    path: "/infantEntrance",
    name: "infantEntrance",
    component: () => import("@/view/infantEntrance.vue"),
  },
  {
    path: "/spEntrance",
    name: "spEntrance",
    component: () => import("@/view/spEntrance.vue"),
  },
  {
    path: "/transfer",
    name: "transfer",
    component: () => import("@/view/transfer.vue"),
  },
  {
    path: "/inquire",
    name: "inquire",
    component: () => import("@/view/inquire.vue"),
  },
  {
    path: "/voucher",
    name: "voucher",
    component: () => import("@/view/voucher.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("@/view/reset.vue"),
  },
  {
    path: "/resetxc",
    name: "resetxc",
    component: () => import("@/view/resetxc.vue"),
  },
  {
    path: "/select",
    name: "select",
    component: () => import("@/view/select.vue"),
  },
  {
    path: "/select2",
    name: "select2",
    component: () => import("@/view/select2.vue"),
  },
  {
    path: "/collect",
    name: "collect",
    component: () => import("@/view/collect.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/view/test.vue"),
  },
  {
    path: "/userHelp",
    name: "userHelp",
    component: () => import("@/view/userHelp.vue"),
  },
]

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  // if (to.meta && to.meta.title) {
  //     document.title = to.meta.title
  // }
  // let idNo = localStorage.getItem('sr_idNo');
  // if (to.path == '/register') return next()
  // 没有token, 强制跳转到登录页
  // if (!idNo && to.path != '/login') return next('/login')
  next()
})

export default router
