<template>
  <div class="indexPage" :style="{ 'min-height': pageHeight + 'px' }">
   <!-- <div class="Demo" v-if="demo1 < 2">
      <div class="demo3" @click="running" v-if="demo1 < 2">系统测试期间，请勿登记，登记无效！</div>
    </div>

     <subtitle :subtitle.sync="pageTitle"></subtitle> 
    <div
      v-if="demo1 >= 2"
      @click="toUserHelpPage()"
      style="
        text-align: right;
        padding: 10px 10px;
        color: #009689;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <span>使用手册</span>
      <img style="width: 14px; height: 14px" src="../assets/images/rightArrow.png" alt />
      
    </div>-->
    <div  v-if="dialogVisible" :style="{ 'height': pageHeight + 'px' }" >
      <div  class="modal-content">
          <iframe src="web/viewer.html" style="height:100%;width:100%;"></iframe>
      </div>
      <van-button
        style="margin-top:5px;"
        color="linear-gradient(315deg, #B1E0DC 0%, #00C3AC 100%)"
        size="large"
        round
        class="submitBtn"
        @click="closePDF()"
        
        >关闭PDF</van-button
      >
    </div>
    <div class="menuBox" v-if="demo1 >= 2">
      <van-grid :gutter="10" :column-num="3" :border="false">
        <van-grid-item
          class="menuItem"
          v-for="item in menuList"
          :key="item.id"
          :class="'menuItem' + item.id"
          @click="toResultPage(item)"
        >
          <van-image class="itemIcon" :src="item.iconUrl" fit="widthFix" />
          <div class="itemTitle">
            <!-- firstLine 5/.. -->
            <div v-if="[4, 9].indexOf(item.id) != -1">
              <div>{{ item.title.slice(0, 5) }}</div>
              <div>{{ item.title.slice(5) }}</div>
            </div>
            <!-- firstLine 4/.. -->
            <div v-else-if="[5, 6, 11, 14, 15].indexOf(item.id) != -1">
              <div>{{ item.title.slice(0, 4) }}</div>
              <div>{{ item.title.slice(4) }}</div>
            </div>
            <!-- firstLine 2/.. -->
            <div v-else-if="[7, 8].indexOf(item.id) != -1">
              <div>{{ item.title.slice(0, 2) }}</div>
              <div>{{ item.title.slice(2) }}</div>
            </div>
            <!-- firstLine 3/.. -->
            <div v-else-if="item.id == 10">
              <div>{{ item.title.slice(0, 3) }}</div>
              <div>{{ item.title.slice(3) }}</div>
            </div>
            <!-- firstLine 6/.. -->
            <div v-else-if="item.id == 12">
              <div>{{ item.title.slice(0, 6) }}</div>
              <div>{{ item.title.slice(6) }}</div>
            </div>
            <!-- auto -->
            <div v-else>{{ item.title }}</div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="tips" v-if="demo1 >= 2">
      <div>数据来源：鄂尔多斯市教育体育局</div>
      <!-- <div>服务热线：0477-8598822</div> -->
    </div>

    

  </div>
</template>

<script>
import subtitle from "@/components/subtitle";
export default {
  components: {
    // subtitle,
  },
  name: "register",
  data() {
    return {
      dialogVisible:false,
      dialogImageUrl:'',
      // demo1: 0, //0弹框  1不弹框
      demo1: 2, //0弹框  1不弹框
      demo2: 0,
      pageHeight: "",
      pageTitle: "操作手册",
      menuList: [
        {
          id: 1,
          title: "幼儿园入学登记",
          iconUrl: require("@/assets/images/icon_1.png"),
          phase: "0",
        },
        {
          id: 2,
          title: "小学入学登记",
          iconUrl: require("@/assets/images/icon_2.png"),
          phase: "1",
        },
        {
          id: 3,
          title: "初中入学登记",
          iconUrl: require("@/assets/images/icon_3.png"),
          phase: "2",
        },
        {
          id: 4,
          title: "幼儿园划片分配查询",
          iconUrl: require("@/assets/images/icon_4.png"),
          phase: "0",
        },
        {
          id: 5,
          title: "小学划片分配查询",
          iconUrl: require("@/assets/images/icon_5.png"),
          phase: "1",
        },
        {
          id: 6,
          title: "初中划片分配查询",
          iconUrl: require("@/assets/images/icon_6.png"),
          phase: "2",
        },
        {
          id: 7,
          title: "小学阳光分班查询",
          iconUrl: require("@/assets/images/icon_7.png"),
          phase: "1",
        },
        {
          id: 8,
          title: "初中阳光分班查询",
          iconUrl: require("@/assets/images/icon_8.png"),
          phase: "2",
        },
        {
          id: 9,
          title: "一年级新生学籍信息采集",
          iconUrl: require("@/assets/images/icon_9.png"),
          phase: "1",
        },
        // {
        //   id: 10,
        //   title: "高层次子女入学",
        //   iconUrl: require("@/assets/images/icon_10.png"),
        //   phase: "",
        // },
        // {
        //   id: 11,
        //   title: "现役军人子女入学",
        //   iconUrl: require("@/assets/images/icon_11.png"),
        //   phase: "",
        // },
        // {
        //   id: 12,
        //   title: "消防救援人员子女入学",
        //   iconUrl: require("@/assets/images/icon_12.png"),
        //   phase: "",
        // },
        {
          id: 13,
          title: "转学登记",
          iconUrl: require("@/assets/images/icon_13.png"),
          phase: "",
        },
        {
          id: 14,
          title: "转学登记结果查询",
          iconUrl: require("@/assets/images/icon_14.png"),
          phase: "",
        },
        {
          id: 15,
          title: "审核查询结果查看",
          iconUrl: require("@/assets/images/icon_15.png"),
          phase: "",
        },
      ],
    };
  },
  created() {
    sessionStorage.removeItem("platformType");
    sessionStorage.removeItem("subtitle");
    sessionStorage.removeItem("areaCode");
    sessionStorage.removeItem("phase");
  },
  mounted() {
    this.setPageHeight();
  },
  methods: {
    running() {
      this.demo1 += 1;

      setTimeout(() => {
        if (this.demo1 != 2) {
          this.demo1 = 0;
        }
      }, 1000);
    },

    toUserHelpPage() {
     // window.open("https://ygrx.ordos.edu.cn:53434/manual/operateDemo.pdf");
     this.dialogImageUrl='https://ygrx.ordos.edu.cn:47474/1846dfa988025000/operateDemo.pdf';
     this.dialogVisible = true;
     this.demo1=1;
      // this.$router.push({ name: "userHelp" });
    },
    toResultPage(info) {
      sessionStorage.setItem("platformType", info.id);
      sessionStorage.setItem("subtitle", info.title);
      if (info.phase != "") {
        sessionStorage.setItem("phase", info.phase);
      } else {
        sessionStorage.removeItem("phase");
      }
      if (info.id == 10 || info.id == 11 || info.id == 12) {
        this.$router.push({ name: "result" });
      } else if (info.id == 15) {
        this.$router.push({ name: "login" });
      } else {
        this.$router.push({ name: "area" });
      }
    },

    //	设置页面最小高度
    setPageHeight() {
      this.pageHeight = document.documentElement["clientHeight"];
      window.onresize = () => {
        this.pageHeight = document.documentElement["clientHeight"];
      };
    },
    closePDF(){
      this.dialogVisible = false;
      this.demo1=3;
    },
  }
};
</script>

<style scoped>
.indexPage {
  box-sizing: border-box;
  position: relative;
  width: 750px;
}
.indexPage .menuBox {
  width: 750px;
  /*margin: 30px auto;*/
  margin-top: 20px;
}
.indexPage .menuBox .menuItem {
  text-align: center;
  border-radius: 10px;
}
.indexPage .menuBox .menuItem /deep/ .van-grid-item__content {
  border-radius: 10px !important;
}
.indexPage .menuBox .menuItem .itemIcon {
  width: 100px;
  height: 100px;
}
.indexPage .menuBox .menuItem .itemTitle {
  width: 90%;
  margin: 20px auto 0;
}
.indexPage .menuBox .menuItem1 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem4 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem7 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem10 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem13 /deep/ .van-grid-item__content {
  background: linear-gradient(
    315deg,
    rgba(177, 224, 220, 0.05) 0%,
    rgba(0, 195, 172, 0.05) 100%
  ) !important;
}
.indexPage .menuBox .menuItem2 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem5 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem8 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem11 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem14 /deep/ .van-grid-item__content {
  background: linear-gradient(
    315deg,
    rgba(127, 211, 253, 0.05) 0%,
    rgba(0, 143, 236, 0.05) 100%
  ) !important;
}
.indexPage .menuBox .menuItem3 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem6 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem9 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem12 /deep/ .van-grid-item__content,
.indexPage .menuBox .menuItem15 /deep/ .van-grid-item__content {
  background: linear-gradient(
    315deg,
    rgba(255, 225, 123, 0.05) 0%,
    rgba(255, 173, 0, 0.05) 100%
  ) !important;
}

.indexPage .tips {
  /*position: absolute;*/
  margin-top: 140px;
  width: 100%;
  /*bottom: 150px;*/
  font-size: 28px;
  font-weight: 500;
  color: #999999;
  line-height: 45px;
  text-align: center;
}
.indexPage .demo {
  width: 100px;
  height: 100px;
  background-color: white;
}
.indexPage .demo3 {
  margin-top: 40%;
  display: flex;

  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.indexPage .Demo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  width: 95%;
  height: 90%;
}
</style>
