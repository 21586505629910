<template>
    <div class="subtitle">
        <van-nav-bar
            :title="subtitle"
            left-arrow
            @click-left="onClickLeft"/>
    </div>
</template>

<script>
    export default {
        name: "subtitle",
        data(){
            return {
            }
        },
        props: ['subtitle'],
        methods: {
            onClickLeft() {
                this.$router.go(-1);
            },
        },
    }
</script>

<style scoped>

</style>