import axios from 'axios';
import { Toast } from 'vant';
import jsrsasignApi from '@/utils/jsrsasign.js';
import login from "../view/login";

const service = axios.create({
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    "test": "Geqy07ejcwD7gt6YY9+kTw=="
  },
  withCredentials: true,
})

if (process.env.NODE_ENV == 'development') {
  service.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV == 'production') {
  // service.defaults.baseURL = 'http://116.136.156.117:53434';
  // service.defaults.baseURL = 'http://118.31.52.56:8080/msb';
  service.defaults.baseURL = 'https://ygrx.ordos.edu.cn:53434';


}

// request拦截
service.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json';
  // config.headers['account'] = sessionStorage.getItem("account");
  config.headers['token'] = sessionStorage.getItem("token");
  let loginType = 1;
  //  TODO  后续根据业务判断
  let platformType = sessionStorage.getItem("platformType");
  if ([1, 2, 3].indexOf(platformType) != -1) {
    config.headers['loginType'] = loginType;
  }
  let obj = {};
  if (config.method == "GET" || config.method == "get") {
    obj = config.params;
  } else {
    obj = config.data;
  }
  config.headers['sign'] = jsrsasignApi.getSign(obj);
  return config
}, error => {
  // console.log(error) // for debug
  Promise.reject(error)
})


// response拦截
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code == '0010') {
      if (res.message) {
        Toast({
          message: res.message,
        });
      }
      // localStorage.removeItem('sr_idNo');
      // localStorage.clear();
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error)// for debug
    return Promise.reject(error)
  }
)

export default service;
