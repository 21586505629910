<template>
    <div class="indexPage" :style="{ 'min-height': pageHeight + 'px'}">
<!--        <div>testPage===>>></div>-->
<!--        <div>url===>>>{{location}}</div>-->
<!--        <div>code===>>>{{code}}</div>-->
<!--        <div>testData===>>>{{testData}}</div>-->
    </div>
</template>

<script>
    export default {
        name: "index",
        data(){
            return{
                pageHeight: "",
                realName: "",
                idCardNumber: "",
                code: "",
                testData: "",
                location: "",
            }
        },
        created() {
            this.getPageData();
        },
        mounted() {
            this.setPageHeight();
        },
        methods: {
            //
            getPageData(){
                // code = "f8862e4a-573b-4340-9049-3d4be6733969";
                // let res = { "code": "0000", "data": { "id_card": "430424199409242712", "real_name": "向永贤" }, "message": "操作成功" };
                // let code = "826e3022-57ad-4f7c-8b35-bacb3405bab7";
                // let res = { "code": "0000", "data": {"id_card": "430103199005043532", "real_name": "刘硕"}, "message": "操作成功" };
                // sessionStorage.setItem("userIdCardNo", res.data.id_card);
                // sessionStorage.setItem("userRealName", res.data.real_name);
                this.$router.push({ name: "index" });
                return;
                if(this.checkSessionData()){
                    return this.$router.push({ name: "index" });
                }else {
                    this.location = window.location;
                    let code = this.GetUrlParams("code");
                    if(!code){
                        this.redirectPage();
                    }else{
                        this.code = code;
                        let params = {code: this.code};
                        this.$http({
                            url: "/msb/login/getToken",
                            method: "GET",
                            params: params,
                        }).then((res) => {
                            this.testData = res;
                            if(res && res.code == "0000"){
                                sessionStorage.setItem("userIdCardNo", res.data.id_card);
                                sessionStorage.setItem("userRealName", res.data.real_name);
                                this.$router.push({ name: "index" });
                            }else {
                                this.$toast({
                                    message: res.message,
                                    icon: 'warning-o',
                                })
                            }
                        }).catch((e)=>{
                            this.$toast({
                                message: "catch:" + e,
                                icon: 'warning-o',
                            });
                        })
                    }
                }
            },

            //
            checkSessionData(){
                let userIdCardNo = sessionStorage.getItem("userIdCardNo");
                let userRealName = sessionStorage.getItem("userRealName");
                if(userIdCardNo && userRealName){
                    return true;
                }
                return false;
            },

            //  重定向
            redirectPage(){
                // window.location.href = "https://app.zwfw.nmg.gov.cn:4443/icity/auth2.0/authorize?app_id=038478ef-311f-40a6-b787-e69fe67bc281&redirect_uri=http://clfund.shudingkj.com/&scope=auth_user&state=";
                window.location.href = "https://app.zwfw.nmg.gov.cn:4443/icity/auth2.0/authorize?app_id=038478ef-311f-40a6-b787-e69fe67bc281&redirect_uri=http://116.136.156.117:47474/&scope=auth_user&state=";
            },

            //获取code
            GetUrlParams(paramsName) {
                // 获取地址栏指定参数的值
                // 获取url中跟在问号后面的部分
                let params = window.location.search;
                // 检测参数是否存在
                if (params.indexOf(paramsName) > -1) {
                    let paramsValue = "";
                    paramsValue = params.substring(
                        params.indexOf(paramsName),
                        params.length
                    );
                    // 检测后面是否还有参数
                    if (paramsValue.indexOf("&") > -1) {
                        // 去除后面多余的参数, 得到最终 paramsName=paramsValue 形式的值
                        paramsValue = paramsValue.substring(0, paramsValue.indexOf("&"));
                        // 去掉参数名, 得到最终纯值字符串
                        paramsValue = paramsValue.replace(paramsName + "=", "");
                        if(paramsValue == "code"){
                            return "";
                        }
                        return paramsValue;
                    }
                    return "";
                }
            },

            //	设置页面最小高度
            setPageHeight () {
                this.pageHeight = document.documentElement['clientHeight']
                window.onresize = () => {
                    this.pageHeight = document.documentElement['clientHeight'];
                }
            },
        }
    }
</script>

<style scoped>
    .indexPage{
        box-sizing: border-box;
        position: relative;
        padding-bottom: 80px;
    }
</style>