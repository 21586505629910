import jsrsasign from 'jsrsasign'

let jsrsasignApi = {
    getSign(obj){
        let arr = new Array();
        let num = 0;
        for (let i in obj) {
            arr[num] = i;
            num++;
        }
        let sortArr = arr.sort();
        //let sortObj = {};    //完成排序值
        let newStr = '';             //自定义排序字符串
        for (let i in sortArr) {
            let isObject = false;
            let isArray = Array.isArray(obj[sortArr[i]]);
            if(isArray && obj[sortArr[i]].length){
                isObject = Object.prototype.toString.call(obj[sortArr[i]][0]) === '[object Object]';
            }
            if(isArray && isObject){
                newStr += sortArr[i] + '=' + JSON.stringify(obj[sortArr[i]]) + '&';
            }else {
                newStr += sortArr[i] + '=' + obj[sortArr[i]] + '&';
            }
            //sortObj[sortArr[i]] = obj[sortArr[i]];
        }
        //去除两侧字符串
        let char = '&'
        newStr = newStr.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');

        // 创建RSAKey对象
        let rsa = new jsrsasign.RSAKey();
        //因为后端提供的是pck#8的密钥对，所以这里使用 KEYUTIL.getKey来解析密钥
        let key = `-----BEGIN PRIVATE KEY-----
        MIICAwIBADANBgkqhkiG9w0BAQEFAASCAe0wggHpAgEAAmgAiG6AazCQJU1qykX+rexZdNg6kqTAGVvoMr6sU25wlh42xqeNawr0aUJ7X7jgn0JtB8skfIe1QSKiXVr2nF3A7P+LK7HsST3vshU0e6KEpaVeQBv/eavr22OOLrWpBDp1V4ZmAy9TxQIDAQABAmd+hcOwJDw5a78yUJEr5/jZ5EEFh6XinpoKnUsgoFXm0aRIvE/oyiL5jr7teX1OFIMabCiW4JzT7i94cmUeVU9IyhyrU7FXA/JF/C411ds5XNJkXcpzwhCPIcngUSdmh36B0X5iZiThAjQOof0+dFdOsJS5Kb3RxL+3Vko4NVaAruwdE9T+T4xasU9O33RX5Mqo8t90YLrGpyK9OMwdAjQJUt5igr4NzXmyEBJcvCFSKZT0l5cZjpmr8By60xOlhKPJE0nAmmdV6lCol8rG7Aqf/IXJAjQNutc5I8bMEgnqV41BZ6AP8cqARSPeaMMpYXNoD9H9QwPMWQT8d7PkpMcglqaKQUbqT4HNAjQIATegh/M30nNq5tV8/J/29HBIVmWJsZ5JucpQs6uobWpY1dB6JZYWcydwt3GGu1yb4qfRAjQF6Ynfe83dmt8tPsS8IKqfVWUkkoaEgbkENDfCj5Dqr4w62NqPqyTtMNh2jasYFhMfowPy
        -----END PRIVATE KEY-----`
        // 将密钥转码
        rsa = jsrsasign.KEYUTIL.getKey(key);
        // 创建Signature对象，设置签名编码算法
        let sig = new jsrsasign.KJUR.crypto.Signature({"alg": "SHA256withRSA"});
        // 初始化
        sig.init(rsa)
        // 传入待加密字符串
        sig.updateString(newStr)
        // 生成密文
        let sign = jsrsasign.hextob64(sig.sign());
        // 对加密后内容进行URI编码
        sign = encodeURIComponent(sign);
        return sign;
    },
}

export default jsrsasignApi;