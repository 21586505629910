<template>
  <div class="loginPage" :style="{ 'min-height': pageHeight + 'px' }">
    <subtitle :subtitle.sync="pageTitle"></subtitle>
    <div class="form">
      <div v-if="platformType == 15" class="xueduan">
        <van-field
          readonly
          clickable
          name="picker"
          :value="valuex"
          label="学段"
          placeholder="点击选择学段"
          @click="showPicker = true"
          style="background-color: #f4f6f6"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
      </div>

      <!-- 考生号 -->
      <div class="formItem">
        <div class="labelTitle"><span class="redTag">*</span> 账号</div>
        <van-field
          v-model="formData.idCardNo"
          class="itemIpt"
          type="text"
          placeholder="请填写学生身份证号"
        />
      </div>
      <!-- 密码 -->
      <div class="formItem">
        <div class="labelTitle"><span class="redTag">*</span> 密码</div>
        <van-field
          v-model="formData.password"
          class="itemIpt"
          type="password"
          :placeholder="tipsMsg"
        />
        <!-- <div style="margin-top: 20px;color: #999999">提示：初始密码为身份证号后6位。</div>-->
      </div>

      <div class="formItem" style="text-align: right; color: #06c4ae">
        <span @click="toForgotPage()">忘记密码 ?</span>
      </div>

      <!--  <div
        class="formItem"
        style="text-align: right; color: #06c4ae"
        v-if="platformPhase == 1"
      >
        <span @click="toForgotPagexc()">忘记密码 ?</span>
      </div>

      <div
        class="formItem"
        style="text-align: right; color: #06c4ae"
        v-if="platformPhase == 2"
      >
        <span @click="toForgotPagexc()">忘记密码 ?</span>
      </div> -->
    </div>
    <div class="btnBox">
      <van-button
        color="linear-gradient(315deg, #B1E0DC 0%, #00C3AC 100%)"
        size="large"
        round
        class="submitBtn"
        @click="onSubmit()"
        :disabled="config.disabled"
        >{{ btnText }}</van-button
      >
    </div>
    <!--        <div class="resultBox">-->
    <!--            <div class="resultLine">-->
    <!--                <van-divider-->
    <!--                    :style="{ color: '#03C4AD', borderColor: '#03C4AD', padding: '0 16px' }">以下是学生信息</van-divider>-->
    <!--            </div>-->
    <!--            <div class="content" v-if="!!resultData.length">-->
    <!--                <div class="resultItem" style="margin-bottom: 20px">-->
    <!--                    <van-cell-group :border="true" inset>-->
    <!--                        <van-cell-->
    <!--                            v-for="item in resultData"-->
    <!--                            :key="item.id"-->
    <!--                            @click="studentLogin(item)">-->
    <!--                            <span :style="{'color': item.disabled ? '#999999' : '#323233'}">-->
    <!--                                {{ `${item.name}( ${item.idCardNo.replace(/^(\d{4})\d{10}(.{4}$)/g, `$1${Array(11).join('*')}$2`)} ) ` }}-->
    <!--                            </span>-->
    <!--                        </van-cell>-->
    <!--                    </van-cell-group>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="btnBox" v-if="showRegister">
      <van-button
        type="default"
        size="large"
        round
        class="registerBtn"
        @click="toRegister()"
        >添加学生</van-button
      >
    </div>
    <div class="tips">
      <div>数据来源：鄂尔多斯市教育体育局</div>
      <div v-if="[10, 11, 12, 15].indexOf(platformType) != -1">
        <div v-if="[15].indexOf(platformType) != -1">
          <div> 东胜区    ：0477-8380725</div>
            <div>  康巴什区  ：0477-8599995</div>
              <div> 达拉特旗  ：0477-5226487</div>
                <div> 准格尔旗  ：0477-3973404</div>
                  <div> 鄂托克前旗：0477-7628010</div>
                    <div> 鄂托克旗  ：0477-6212970</div>
                      <div> 杭锦旗    ：0477-6622797</div>
                        <div> 乌审旗    ：0477-7586977</div>
                          <div> 伊金霍洛旗：0477-8691592</div>
        </div>
        <div v-else>
          服务热线：0477-8598822
        </div>
        
      </div>
      <div v-else>
        <div>业务咨询电话：</div>
        <div v-if="areaCode == '150602' || areaCode == '15060201'">
          东胜区教体局：{{
            platformType == 1 || platformType == 4
              ? "0477-8380472"
              : "0477-8380725"
          }}
        </div>
        <div v-else-if="areaCode == '150603'">
          康巴什区教体局：{{
            platformType == 1 || platformType == 4
              ? "0477-8599995"
              : "0477-8599995"
          }}
        </div>
        <div v-else-if="areaCode == '150621'">
          达拉特旗教体局：{{
            platformType == 1 || platformType == 4
              ? "0477-5214364"
              : "0477-5226487"
          }}
        </div>
        <div v-else-if="areaCode == '150622'">
          准格尔旗教体局：{{
            platformType == 1 || platformType == 4
              ? "0477-3970106"
              : "0477-3970106"
          }}
        </div>
        <div v-else-if="areaCode == '150623'">
          鄂托克前旗教体局：{{
            platformType == 1 || platformType == 4
              ? "0477-7625571"
              : "0477-7628010"
          }}
        </div>
        <div v-else-if="areaCode == '150624'">
          鄂托克旗教体局：{{
            platformType == 1 || platformType == 4
              ? "0477-6214460"
              : "0477-6212970"
          }}
        </div>
        <div v-else-if="areaCode == '150625'">
          杭锦旗教体局：{{
            platformType == 1 || platformType == 4
              ? "0477-6628450"
              : "0477-6622797"
          }}
        </div>
        <div v-else-if="areaCode == '150626'">
          乌审旗教体局：{{
            platformType == 1 || platformType == 4
              ? "0477-7586987"
              : "0477-7586977"
          }}
        </div>
        <div v-else-if="areaCode == '150627'">
          伊金霍洛旗教体局：{{
            platformType == 1 || platformType == 4
              ? "0477-8691571"
              : "0477-8691592"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subtitle from "@/components/subtitle";
export default {
  name: "login",
  components: {
    subtitle,
  },
  data() {
    return {
      areaCode: "",
      pageHeight: "",
      pageTitle: "",
      formData: {
        idCardNo: "",
        password: "",
      },
      platformType: "",
      platformPhase: "",
      config: {
        title: "",
        reqUrl: "", //  请求地址
        methods: "", //  请求方法
        type: "", //  1,入学 2,转学 3,分班 4,采集（1,消防救援人员 2,现役军人 3,高层次人才子女）
        phase: "", //  学段
        openType: "", //  1,登记    2,结果查询
        btnText: "", //  按钮文本
        disabled: "", //  按钮禁用
      },
      tipsMsg: "请填写密码",
      configs: {
        1: {
          title: "幼儿园入学登记",
          reqUrl: "/msb/yry/login",
          methods: "POST",
          type: "1",
          phase: "0",
          openType: "1",
          btnText: "去登记",
          disabled: false,
        },
        2: {
          title: "小学入学登记",
          reqUrl: "/msb/login/login",
          methods: "POST",
          type: "1",
          phase: "1",
          openType: "1",
          btnText: "去登记",
          disabled: false,
        },
        3: {
          title: "初中入学登记",
          reqUrl: "/msb/login/login",
          methods: "POST",
          type: "1",
          phase: "2",
          openType: "1",
          btnText: "去登记",
          disabled: false,
        },
        4: {
          title: "幼儿园划片分配查询",
          reqUrl: "/msb/yry/login",
          methods: "POST",
          type: "1",
          phase: "0",
          openType: "2",
          btnText: "去查询",
          disabled: false,
        },
        5: {
          title: "小学划片分配查询",
          reqUrl: "/msb/login/login",
          methods: "POST",
          type: "1",
          phase: "1",
          openType: "2",
          btnText: "去查询",
          disabled: false,
        },
        6: {
          title: "初中划片分配查询",
          reqUrl: "/msb/login/login",
          methods: "POST",
          type: "1",
          phase: "2",
          openType: "2",
          btnText: "去查询",
          disabled: false,
        },
        7: {
          title: "小学阳光分班查询",
          reqUrl: "/msb/login/login",
          methods: "POST",
          type: "3",
          phase: "1",
          openType: "",
          btnText: "去查询",
          disabled: false,
        },
        8: {
          title: "初中阳光分班查询",
          reqUrl: "/msb/login/login",
          methods: "POST",
          type: "3",
          phase: "2",
          openType: "",
          btnText: "去查询",
          disabled: false,
        },
        9: {
          title: "一年级新生学籍信息采集",
          reqUrl: "/msb/login/login_cj",
          methods: "POST",
          type: "4",
          phase: "1",
          openType: "1",
          btnText: "去填写",
          disabled: false,
        },
        10: {
          title: "高层次子女入学",
          reqUrl: "/msb/children/login/login",
          methods: "POST",
          type: "3",
          phase: "",
          openType: "",
          btnText: "去登记",
          disabled: false,
        },
        11: {
          title: "现役军人子女入学",
          reqUrl: "/msb/children/login/login",
          methods: "POST",
          type: "2",
          phase: "",
          openType: "",
          btnText: "去登记",
          disabled: false,
        },
        12: {
          title: "消防救援人员子女入学",
          reqUrl: "/msb/children/login/login",
          methods: "POST",
          type: "1",
          phase: "",
          openType: "",
          btnText: "去登记",
          disabled: false,
        },
        13: {
          title: "转学登记",
          reqUrl: "/msb/login/login_t",
          methods: "POST",
          type: "",
          phase: "",
          openType: "1",
          btnText: "去登记",
          disabled: false,
        },
        14: {
          title: "转学登记结果查询",
          reqUrl: "/msb/login/login_t",
          methods: "POST",
          type: "",
          phase: "",
          openType: "2",
          btnText: "去查询",
          disabled: false,
        },
        15: {
          title: "审核查询结果查看",
          reqUrl: "/msb/student/getVerifyProgress",
          methods: "GET",
          type: "",
          phase: "",
          openType: "2",
          btnText: "去查询",
          disabled: false,
        },
      },
      showRegister: false,
      resultData: [
        // { id: 1, name: "某某", idCardNo: "150602201411223938", password: "a123456"},
        // { id: 2, name: "某哦", idCardNo: "150602200802060940", password: "a123456"},
        // { id: 3, name: "某哦", idCardNo: "150602201406020941", password: "a123456"},
      ],
      // 学段
      valuex: "",
      valuexd: "",
      columns: ["幼儿园", "中小学"],
      showPicker: false,
    };
  },

  created() {
    this.getPageData();
  },
  mounted() {
    this.setPageHeight();
  },
  methods: {
    // 学段选择器函数
    onConfirm(value) {
      this.valuex = value;
      this.valuexd = value == "幼儿园" ? 1 : 2;

      console.log("value", this.valuexd);
      this.showPicker = false;
    },

    //  获取页面数据
    getPageData() {
      this.platformType = sessionStorage.getItem("platformType");
      this.platformType = this.platformType ? Number(this.platformType) : 0;
      if ([1, 2, 3, 10, 11, 12, 13].indexOf(this.platformType) != -1) {
        this.showRegister = true;
      }
      if ([4, 5, 6, 7, 8 , 9].indexOf(this.platformType) != -1) {
        this.tipsMsg = "请填写密码（监护人1手机号码后6位）";
      } 
     // else if ([9].indexOf(this.platformType) != -1) {
     //   this.tipsMsg = "请填写密码（身份证号后6位）";
     // }
      this.areaCode = sessionStorage.getItem("areaCode");
      // if(this.areaCode == '150602'){
      //     this.showRegister = false;
      // }
      this.config = this.configs[this.platformType] || {};
      this.pageTitle = this.config.title;
      window.document.title = "阳光入学服务系统";
      this.btnText = this.config.btnText;
      let idCardNo = sessionStorage.getItem("userIdCardNo");
      let listType = 4;
      if (this.platformType > 0 && this.platformType < 10) {
        listType = 4;
      } else if (this.platformType == 10) {
        listType = 3;
      } else if (this.platformType == 11) {
        listType = 2;
      } else if (this.platformType == 12) {
        listType = 1;
      } else if (this.platformType == 13 || this.platformType == 14) {
        listType = 5;
      }
      this.platformPhase = sessionStorage.getItem("phase");
      this.getBindStudents(idCardNo, listType);
    },

    //  获取绑定学生列表
    getBindStudents(idCardNo, listType) {
      this.$http({
        url: `/msb/binding/student/getStudents`,
        method: "GET",
        params: {
          id_card: idCardNo,
          userType: listType,
        },
      }).then((res) => {
        if (res && res.code == "0000") {
          this.resultData = [];
          let list = res.data || [];
          list.forEach((item) => {
            let obj = {
              id: item.id,
              name: item.studentName,
              idCardNo: item.studentIdCard,
              password: item.password,
            };
            if (this.platformPhase != "") {
              obj.disabled = item.bmxddm != this.platformPhase;
            }
            this.resultData.push(obj);
          });
        }
      });
    },

    //  选择学生
    studentLogin(info) {
      if (info.disabled) {
        return;
      }
      this.formData.idCardNo = info.idCardNo;
      this.formData.password = info.password;
    },

    toForgotPage() {
      //幼儿园修改
      if(this.platformType==1||this.platformType==4){
        this.$router.push({ name: "forgot" });
        //其余修改
      }else if(this.platformType==15){
        if (!this.valuexd) {
          return this.$toast("请选择学段登记！");
        }
        if(this.valuexd==1){
          this.$router.push({ name: "forgot" });
        }else{
          this.$router.push({ name: "forgotxc" });
        }
      }else{
        this.$router.push({ name: "forgotxc" });
      }
      
    },

    toForgotPagexc() {
      this.$router.push({ name: "forgotxc" });
    },

    //  立即提交
    onSubmit() {
      // console.log(this.formData);
      // console.log("====>>>",!this.config.reqUrl);
      if (!this.config.reqUrl) {
        return;
      }
      // if(!this.resultData.length){
      //     return this.$toast("请先添加学生！");
      // }
      if (!this.formData.idCardNo || !this.formData.password) {
        return this.$toast("请选择学生去登记！");
      }

      if (this.platformType == 15) {
        if (!this.valuexd) {
          return this.$toast("请选择学段登记！");
        }
      }
      let data = {
        sfzhm: this.formData.idCardNo,
        password: this.formData.password,
      };

      this.config.type && (data.type = this.config.type);
      this.config.phase && (data.bmxddm = this.config.phase);
      this.config.openType && (data.openType = this.config.openType);
      console.log("下面打印data", data);
      this.$http({
        url:
          this.config.methods == "GET"
            ? this.config.reqUrl +
              "?sfzhm=" +
              this.formData.idCardNo +
              "&password=" +
              this.formData.password +
              "&xd=" +
              this.valuexd
            : this.config.reqUrl,
        method: this.config.methods,
        data: data,
      })
        .then((res) => {
          if (res && res.code == "0000") {
            let data = res.data;
            console.log("data==>>>", data);
            this.toTargetPage(data);
          } else {
            this.$toast({
              message: res.message,
              icon: "warning-o",
            });
          }
        })
        .catch((e) => {
          this.$toast({
            message: e,
            icon: "warning-o",
          });
        });
    },

    toRegister() {
      this.$router.push({ name: "register" });
    },

    toTargetPage(data) {
      // console.log("wang", data);
      data.sfzhm && sessionStorage.setItem("account", data.sfzhm);
      data.token && sessionStorage.setItem("token", data.token);
      data.bmqqdm && sessionStorage.setItem("areaCode", data.bmqqdm);
      if (this.platformType == 15) {
        data && sessionStorage.setItem("data2222", JSON.stringify(data));
      }

      let targetName = "";
      if ([1, 2, 3].indexOf(this.platformType) != -1) {
        sessionStorage.setItem("areaCode", data.bmqqdm);
        if (this.platformType == 1) {
          targetName = "infantEntrance";
        } else {
          targetName = "entrance";
        }
      } else if ([4, 5, 6, 7, 8, 14, 15].indexOf(this.platformType) != -1) {
        targetName = "inquire";
      } else if ([9].indexOf(this.platformType) != -1) {
        targetName = "collect";
      } else if ([10, 11, 12].indexOf(this.platformType) != -1) {
        targetName = "spEntrance";
      } else if ([13].indexOf(this.platformType) != -1) {
        targetName = "transfer";
      }
      if (targetName) {
        this.$router.push({ name: targetName });
      }
    },

    //	设置页面最小高度
    setPageHeight() {
      this.pageHeight = document.documentElement["clientHeight"];
      window.onresize = () => {
        this.pageHeight = document.documentElement["clientHeight"];
      };
    },
  },
};
</script>

<style scoped>
.loginPage {
  box-sizing: border-box;
  position: relative;
  width: 750px;
}
.loginPage .pageTitle {
  color: #000000;
  font-size: 42px;
  font-weight: bold;
  line-height: 59px;
  margin-bottom: 57px;
  margin-top: 72px;
}
.loginPage .form {
  padding: 73px;
}
/* .loginPage .form .xueduan {
  font-size: 26px;
  font-weight: 400;
  color: #333333;
} */

.loginPage .form .formItem {
  margin-bottom: 50px;
  font-size: 26px;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.loginPage .form .formItem .labelTitle {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 400;
  color: #666666;
  line-height: 45px;
}
.loginPage .form .formItem .labelTitle .redTag {
  color: #f23c3c;
}
.loginPage .form .formItem .itemIpt {
  display: inline-block;
  width: 100%;
  /*height: 44px;*/
  padding: 26px;
  border-radius: 10px;
  border: none;
  background: #f4f6f6;
  font-size: 28px;
  font-weight: 500;
  color: #999999;
  line-height: 45px;
}
.loginPage .form .formItem .itemIpt {
  vertical-align: middle;
}
.loginPage .tips {
  /*position: absolute;*/
  width: 100%;
  /*bottom: 150px;*/
  margin-top: 100px;
  margin-bottom: 100px;
  font-size: 28px;
  font-weight: 500;
  color: #999999;
  line-height: 45px;
  text-align: center;
}
.loginPage .btnBox {
  width: 750px;
  background: #ffffff;
  overflow: hidden;
  text-align: center;
  padding: 20px 0;
}
.loginPage .btnBox button {
  width: 90%;
  margin: 0 auto;
}
.loginPage .btnBox .registerBtn {
  border-color: #999999;
  color: #999999;
}
.loginPage .resultBox .content .van-cell-group {
  box-shadow: 0px 3px 6px 3px rgba(153, 153, 153, 0.04);
  border: 2px solid #f5f5f5;
}
</style>

<style lang="scss">
.xueduan {
  // font-size: 26px;
  // font-weight: 400;
  // color: #333333;

  .van-cell__title {
    font-size: 0.426667rem;
    font-weight: 400;
    color: #666;
    transform: translateX(-10px);
  }

  // .van-field__body {
  //   color: red;
  // }
}
</style>
