<template>
  <div id="app">
    <router-view />
    <!-- <div
      class="animationDiv"
      :class="animationModel.visible ? '' : 'animationDivOn'"
    >
      <div class="bacPic">
        <div
          class="invisibleBt"
          @dblclick="animationModel.visible = false"
        ></div>
      </div>
      <div class="TextDiv">
        <span class="TextOne">鄂尔多斯中小学入学登记时间</span>
        <span class="TextTwo">小学：5月11 ~ 17号</span>
        <span class="TextThree">初中：5月18 ~ 24号</span>
        <span class="TextFour">注:当前为测试阶段，请勿登录报名</span>
      </div>
      <div class="tips">
        <div style="display: flex; justify-content: space-between">
          <div>数据来源：</div>
          <div>鄂尔多斯市教育体育局</div>
        </div>

        <div style="display: flex; justify-content: space-between">
          <div>市教体局：</div>
          <div>0477-8598822；</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>东胜区：</div>
          <div>0477-8380725；</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>康巴什区：</div>
          <div>0477-8599995；</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>达拉特旗:</div>
          <div>0477-5226487；</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>准格尔旗：</div>
          <div>0477-3868652；</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>鄂托克前旗：</div>
          <div>0477-7628010；</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>鄂托克旗：</div>
          <div>0477-6212970；</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>杭锦旗：</div>
          <div>0477-6622797；</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>乌审旗：</div>
          <div>0477-7586977；</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>伊金霍洛旗：</div>
          <div>0477-8691592；</div>
        </div>
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      animationModel: {
        visible: true,
      },
    };
  },
  components: {},
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  margin: 0 auto;
  max-width: 750px;
  /*border: 1px solid #2c3e50;*/
}

.animationDiv {
  // display: flex;
  // space-between

  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 151;
  background: #fff;
  transition: all ease 0.5s;
  font-size: 30px;
  // box-sizing: border-box;
  // position: relative;
  // width: 750px;
  // background: url("assets/images/promptBg.png") no-repeat top;
  // background-size: 750px 192px;
}
.bacPic {
  width: 750px;
  height: 192px;
  background: url("assets/images/promptBg.png") no-repeat top;
  background-size: 750px 192px;
  .invisibleBt {
    width: 150px;
    height: 150px;
    position: absolute;
    right: 0;
  }
}
.tips {
  /*position: absolute;*/
  margin-top: 140px;
  width: 60%;
  margin-left: 20%;
  /*bottom: 150px;*/
  font-size: 28px;
  font-weight: 500;
  color: #999999;
  line-height: 45px;
  text-align: center;
}
.animationDivOn {
  left: -100vw;
}
.TextDiv {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  // background: red;
  background: #fbfbfb;
  width: 90%;
  padding: 50px 0;
  border-radius: 10px;
  // position: absolute;
  // top: -50px;
  margin-top: -100px;
  span {
    text-align: center;
  }
  .TextOne {
    font-size: 40px;
    font-weight: 700;
  }
  .TextTwo {
    font-size: 30px;
    margin-top: 60px;
  }
  .TextThree {
    font-size: 30px;
    margin-top: 30px;
  }
  .TextFour {
    font-size: 30px;
    margin-top: 100px;
  }
}
</style>
