import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'
import httpRequest from '@/service/api'
import router from './router'

import 'vant/lib/index.css';
import 'lib-flexible/flexible'

Vue.use(Vant);
Vue.prototype.$http = httpRequest;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
